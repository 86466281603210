<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="695.271"
    height="695.271"
    viewBox="0 0 695.271 695.271"
  >
    <path 
      d="M0,659.191,299.929,359.262V0"
      transform="translate(219.153 686.736) rotate(-135)"
      fill="none"
      stroke="#fec000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="10"
      stroke-dasharray="1 20"
    />
  </svg>
</template>
