<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="42.767" 
    height="35.457" 
    viewBox="0 0 42.767 35.457"
  >
    <title>Arrow Right</title>
    <rect 
      width="36.068" 
      height="6.183" 
      transform="translate(0 14.923)" 
      fill="#e1001e"
    />
    <path 
      d="M25.072,25.072H0V17.76H17.76V0h7.312V25.071Z" 
      transform="translate(7.31 17.728) rotate(-45)" 
      fill="#e1001e"
    />
  </svg>
</template>
