<template>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
        timer: null 
    } 
  },
  mounted() {
    this.idleCheck()
    // Add event listeners for all the "trigger" events that show a user is interacting
    document.addEventListener('mousemove', this.idleCheck);
    document.addEventListener('mousedown', this.idleCheck);
    document.addEventListener('touchstart', this.idleCheck);
    document.addEventListener('click', this.idleCheck);
    document.addEventListener('scroll', this.idleCheck);
    document.addEventListener('keydown', this.idleCheck);
  },
  unmounted() {
    // Remove event listeners for all the "trigger" events that show a user is interacting
    document.removeEventListener('mousemove', this.idleCheck);
    document.removeEventListener('mousedown', this.idleCheck);
    document.removeEventListener('touchstart', this.idleCheck);
    document.removeEventListener('click', this.idleCheck);
    document.removeEventListener('scroll', this.idleCheck);
    document.removeEventListener('keydown', this.idleCheck);
  },
  methods: {
    idleCheck() {
        // Check if the timer is running, if so clear it 
        if (this.timer) {
            clearTimeout(this.timer)
        }

        // If the timer reaches it's end, after 5 minutes, then reset the score and redirect the user to the front page
        this.timer = setTimeout(() => {
            this.$store.commit('resetScore');
            this.$router.push('/');
        }, (1000 * (5 * 60)))
    }
  }
}
</script>

<!-- Global styles -->
<style>
@font-face {
    font-family: 'MahsuriSansMTW01';
    src: url('/fonts/MahsuriSansMTW01-Bold.woff2') format('woff2'),
        url('/fonts/MahsuriSansMTW01-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mahsuri Sans MT Std';
    src: url('/fonts/MahsuriSansMTStd-Light.woff2') format('woff2'),
        url('/fonts/MahsuriSansMTStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mahsuri Sans MT Std';
    src: url('/fonts/MahsuriSansMTStd-Regular.woff2') format('woff2'),
        url('/fonts/MahsuriSansMTStd-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.brand-font-bold {
    font-family: 'MahsuriSansMTW01', Helvetica, Arial, sans-serif;;
    font-weight: bold;
    font-style: normal;
}

p.brand-font-bold {
  font-size: 100px;
}

.brand-font-light {
    font-family: 'Mahsuri Sans MT Std', Helvetica, Arial, sans-serif;;
    font-weight: 300;
    font-style: normal;
}

.brand-font {
    font-family: 'Mahsuri Sans MT Std', Helvetica, Arial, sans-serif;;
    font-weight: 500;
    font-style: normal;
}

html, body, #app {
    min-height: 100vh;
    margin: 0;
}

body {
  background-color: #304251;
  overflow: hidden;
}

#app {
  font-family: 'Mahsuri Sans MT Std', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

/* Styled here due to scoping/binding issue on creating dynamic HTML elements */
.content-overlay__description strong {
  font-size: 1.2em;
  text-decoration: underline;
}
</style>
