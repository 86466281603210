const state = () => ({
  scores: {}
})

const getters = {
  totalScore: (state) => {
    // Convert the object to an array of values and combine them
    return Object.values(state.scores).reduce((a, b) => a + b, 0)
  }
}

const mutations = {
  adjustScore (state, payload) {
    // Store the score as an object property per question, per scenario, to allow traversing of questions and adjusting answers
    state.scores[payload.index] = payload.score
  },
  resetScore (state) {
    // Reset the score to a blank object
    state.scores = {}
  }
}

export default {
  state,
  getters,
  mutations
}
