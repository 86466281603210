<template>
  <div>
    <BgLine class="bg-top" />

    <div class="home">
      <h1 
        class="brand-font-bold" 
        v-html="content.title" 
      />
      <p 
        class="brand-font-bold" 
        v-html="content.introText" 
      />

      <div class="continue-btn brand-font-bold">
        <router-link
          :to="scenarioLink"
          role="button"
          :title="content.buttonText"
        >
          {{ content.buttonText }}
          <ArrowRight />
        </router-link>
      </div>
    </div>

    <div class="bg-bottom">
      <BgLine />
    </div>
  
    <div
      class="text-toggle"
      @click="increaseFontSize"
    >
      <IncreaseFontSize />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Content from '@/content/home.json'
import Manifest from '@/content/scenarios/manifest.json'
import ArrowRight from '@/components/icons/ArrowRight.vue'
import BgLine from '@/components/icons/BgLine.vue'
import IncreaseFontSize from '@/components/icons/IncreaseFontSize.vue'

export default {
  name: 'Home',
  components: {
    ArrowRight,
    BgLine,
    IncreaseFontSize
  },
  data() {
    return {
      content: Content
    }
  },
  computed: {
    scenarioLink() {
      // Set to question 1
      const questionId = 1

      // Get the last played scenario from storage, if there is any and remove it if so
      const lastScenario = parseInt(localStorage.getItem('last-scenario')) ?? null
      localStorage.removeItem('last-scenario')

      // Get the list of scenarios from the manifest
      const scenarios = Manifest.scenarios

      // Filter them removing the last played scenario
      let filteredScenarios = scenarios.filter(item => item !== lastScenario)

      // If there are no scenarios left somehow then remove the filter
      if (filteredScenarios.length === 0) {
        filteredScenarios = scenarios
      }

      // Randomly pick an alternative scenario by index
      const scenarioId = filteredScenarios[Math.floor(Math.random() * filteredScenarios.length)] 

      // Redirect
      return `/scenario/${scenarioId}/question/${questionId}`
    }
  },
  methods: {
    increaseFontSize() {
      if (document.body.classList.contains('increased-font-size')) {
        // Do something
        document.body.classList.remove('increased-font-size')
      } else {
        document.body.classList.add('increased-font-size')
      }
    },
  }
}
</script>

<style>
  .home {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .increased-font-size p {
    font-size: 120px;
  }
  
  .increased-font-size .continue-btn a {
    font-size: 90px;
  }

  .text-toggle {
    position: absolute;
    bottom: 100px;
    right: 100px;
    background: #FEC000;
    border-radius: 50%;
    padding: 58px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    z-index: 9999;
  }

  .text-toggle svg {
    width: 150px;
  }

  .increased-font-size .content-overlay {
    top: 1em;
    width: 60% !important;
    font-size: 90px !important;
  }

  .increased-font-size .page_content--response > div.page_content--hint p,
  .increased-font-size .page_content--answers button,
  .increased-font-size .page_content--answers button small,
  .increased-font-size .page_content--question-num,
  .increased-font-size .page_content--response {
    font-size: 90px !important;
  }

  .bg-top {
    transform: scale(1.5) rotate(-225deg);
    position: absolute;
    left: -170px;
    top: 0;
  }

  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding-left: 130px;
  }

  .bg-bottom svg {    
    transform: scale(1.5);
    top: 160px;
    position: relative;
    left: 40px;
  }

  h1 {
    font-size: 314px; 
    color: #fff;
    margin-top: 0;
  }

  h1, h1 * {
    line-height: .9;
  }

  h1 span {
    color: #E1001E;
  }

  h1 span small {
    font-size: .65;
  }

  p {
    font-size: 70px;
    line-height: 1.2;
    margin-bottom: 2em;
  }

  .continue-btn a {
    background-color: #FEC000;
    display: inline-block;
    font-size: 99px;
    color: #E1001E;
    text-decoration: none;
    padding: .85em 1.3em;
    border-radius: 2em;
  }

  .continue-btn a svg {
    transform: scale(2.5);
    margin-left: .5em;
    top: -.15em;
    position: relative;
  }
</style>
