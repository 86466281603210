import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/score',
    name: 'Score',
    // route level code-splitting
    // this generates a separate chunk for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "score" */ '../views/Score.vue')
  },
  {
    path: '/scenario/:id/complete',
    name: 'Scenario Complete',
    // route level code-splitting
    // this generates a separate chunk for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "complete" */ '../views/Complete.vue')
  },
  {
    path: '/scenario/:id/question/:qid/answer',
    name: 'Scenario Answer',
    // route level code-splitting
    // this generates a separate chunk for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "question" */ '../views/Question.vue')
  },
  {
    path: '/scenario/:id/question/:qid',
    name: 'Scenario Question',
    // route level code-splitting
    // this generates a separate chunk for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "scenario" */ '../views/Scenario.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
